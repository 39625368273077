@import url('https://fonts.googleapis.com/css2?family=Kdam+Thmor+Pro&display=swap');
body {
  margin: 0;
  font-family: 'Kdam Thmor Pro', sans-serif;
  background-color: #282c34;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
